import fetch from "cross-fetch";
import { getBrowserFingerprint } from "fingerprint-browser";
import { config } from "./config";
import { UrlParams } from "./types";

function log(...data: any[]) {
  if (window.midastrackr.debug) {
    console.log("[MidasTrackr] " + new Date().toISOString() + " -", ...data);
  }
}

async function delayAsync(timeout = 500) {
  return new Promise(res => setTimeout(res, timeout));
}

async function getCTAButtons() {
  const TRIES = 5;

  for (let i = 1; i <= TRIES; i++) {
    log(`getting cta buttons try ${i} of ${TRIES}`);

    const btns: HTMLAnchorElement[] = [];

    document
      .querySelectorAll<HTMLAnchorElement>(
        window.midastrackr.btnSelector || "a#cta"
      )
      .forEach(x => {
        if (x.href) {
          btns.push(x);
        }
      });

    if (!btns.length) {
      log(`no cta button found`);

      if (i < TRIES) {
        await delayAsync(500 * i);
      }
    } else {
      log(`${btns.length} cta button(s) found`);
      return btns;
    }
  }
}

function getFingerprint() {
  log("getting fingerprint");
  const fingerprint = getBrowserFingerprint(window.midastrackr.debug);

  if (!fingerprint) {
    log("fingerprint not found");
  } else {
    log("fingerprint", fingerprint);
  }

  return fingerprint;
}

async function getMClid(fingerprint: string, urlParams: UrlParams) {
  log("getting mclid");

  const getMclidUrl = new URL("tracking/get-mclid", config.api());
  getMclidUrl.searchParams.set("BrowserFingerprint", fingerprint);
  getMclidUrl.searchParams.set("ProjectId", window.midastrackr.pid);
  getMclidUrl.searchParams.set("ClickedAt", new Date().toISOString());

  for (const urlParam in urlParams) {
    const value = urlParams[urlParam as keyof UrlParams];

    if (value) {
      getMclidUrl.searchParams.set(urlParam, value);
    }
  }

  const getMClidResponse = await fetch(getMclidUrl).then(x => x.text());

  log("response", getMClidResponse);

  if (!getMClidResponse) {
    log("could not get mclid");
    return;
  }

  return getMClidResponse;
}

async function getTags() {
  log("getting tags");

  const getTagsUrl = new URL("salesplatform/tags", config.api());

  const getTagsResponse = await fetch(getTagsUrl).then(
    x => x.json() as Promise<string[]>
  );
  log("response", getTagsResponse);

  if (!getTagsResponse?.length) {
    log("could not get tags");
    return;
  }

  return getTagsResponse;
}

function getUrlParams(): UrlParams {
  log("getting url params");

  const urlParams = new URLSearchParams(window.location.search);

  return {
    UtmSource: urlParams.get("utm_source"),
    UtmMedium: urlParams.get("utm_medium"),
    UtmCampaign: urlParams.get("utm_campaign"),
    UtmTerm: urlParams.get("utm_term"),
    UtmContent: urlParams.get("utm_content"),
    GClid: urlParams.get("gclid"),
    MsClid: urlParams.get("msclid"),
    MidasAdId: urlParams.get("midas_adid"),
    TestClick: urlParams.get("testClick")
  };
}

function replaceCTAButtonUrl(
  btns: HTMLAnchorElement[],
  mclid: string,
  tags: string[]
) {
  log("replacing cta urls");

  for (let i = 0; i < btns.length; i++) {
    const btn = btns[i];
    const ctaUrl = btn.getAttribute("href")!;

    log(`btn ${i} old url`, ctaUrl);

    const url = new URL(ctaUrl);
    const originalUrl = new URL(ctaUrl);

    originalUrl.searchParams.forEach((_, key) => {
      if (tags.indexOf(key) !== -1) {
        url.searchParams.delete(key);
        url.searchParams.set(key, mclid);
      }
    });

    const newUrl = url.toString();

    log(`btn ${i} new url`, newUrl);

    btn.setAttribute("href", newUrl);
  }
}

function setCTAButtonListener(btns: HTMLAnchorElement[], mclid: string) {
  log("setting cta buttons listeners");

  for (const btn of btns) {
    btn.addEventListener("click", () => {
      log("cta button clicked");

      const url = new URL("tracking/cta", config.api());
      url.searchParams.set("projectId", window.midastrackr.pid);
      url.searchParams.set("midasClid", mclid);

      fetch(url, { method: "POST" })
        .then(x => x.text())
        .then(response => {
          log("response", response);
        });
    });
  }
}

async function main() {
  log("starting");

  if (!window.midastrackr?.pid || !window.midastrackr?.uid) {
    log("missing pid or uid");
    return;
  }

  const ctaButtons = await getCTAButtons();
  if (!ctaButtons) return;

  const fingerprint = getFingerprint();
  if (!fingerprint) return;

  const urlParams = getUrlParams();

  const mclid = await getMClid(fingerprint, urlParams);
  if (!mclid) return;

  setCTAButtonListener(ctaButtons, mclid);

  const tags = await getTags();
  if (!tags) return;

  replaceCTAButtonUrl(ctaButtons, mclid, tags);

  window.midastrackr.done = true;
  window.midastrackr.mclid = mclid;
}

main();
